import React, { useState, useEffect, useRef } from 'react';

import './Mint.css';

import part1 from './assets/images/part1.mov'
import part2 from './assets/images/part2.mov'
import mintbutton from './assets/images/mintbutton.gif'

const Mint = ({ mintOnClick }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [step, setStep] = useState(1);
  const videoRef = useRef(null);
  const gifButtonRef = useRef(null);
  const loopStartTime = 14;
  const loopEndTime = 24;
  const gifButtonClicked = useRef(false);
  const [displayBtn, setDisplayBtn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (step === 2) {
      const videoElement = videoRef.current;

      const handleVideoTimeUpdate = () => {
        if (!gifButtonClicked.current) {
          if (videoElement.currentTime >= loopEndTime) {
            videoElement.currentTime = loopStartTime;
          }
        }
      };

      const handleGifButtonClick = () => {
        gifButtonClicked.current = true;
        setStep(3);
      };

      videoElement.currentTime = 0;
      videoElement.addEventListener('timeupdate', handleVideoTimeUpdate);
      videoElement.play();

      return () => {
        videoElement.removeEventListener('timeupdate', handleVideoTimeUpdate);
      };
    }
  }, [step]);

  useEffect(() => {
    setTimeout(() => {
      setStep(2)
      setTimeout(() => {
        setDisplayBtn(true);
      }, 13000)
    }, 20000)
  }, [])

  const handleVideoEnd = () => {
    if (step === 3) {
      const videoElement = videoRef.current;
      videoElement.currentTime = 33;
      videoElement.play();
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <div className={'video-container'}>
        {step === 1 && (
          <>
            <video
              ref={videoRef}
              className={'fullscreen-video'}
              autoPlay
              muted
              loop
              preload="metadata"
              playsInline
            >
              <source src={part1} type="video/mp4" />
            </video>
            <a
              className={'clickable-area'}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setStep(2);
                setTimeout(() => {
                  setDisplayBtn(true);
                }, 13000)
              }}
            ></a>
          </>
        )}
        {step === 2 && (
          <>
            <video
              ref={videoRef}
              className={'fullscreen-video2'}
              autoPlay
              muted
              preload="metadata"
              playsInline
              onEnded={handleVideoEnd}
            >
              <source src={part2} type="video/mp4" />
            </video>
            <div className={'overlay'}>
              {displayBtn ? <img
                src={mintbutton}
                alt="Overlay GIF"
                className={'overlay-gif'}
                onClick={mintOnClick}
                ref={gifButtonRef}
                style={{
                  cursor: 'pointer',
                  width: '150px'
                }}

              /> : <></>}
            </div>
            <a
              className={'clickable-area'}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                //setStep(3);
              }}
            ></a>
          </>
        )}
        {step === 3 && (
          <>
            <video
              ref={videoRef}
              className={'fullscreen-video'}
              autoPlay
              muted
              preload="metadata"
              playsInline
              onEnded={handleVideoEnd}
            >
              <source src="part2.mov#t=25" type="video/mp4" />
            </video>
            <a
              className={'clickable-area'}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setStep(3);
              }}
            ></a>
          </>
        )}
      </div>
    </div>
  );
};

export default Mint;